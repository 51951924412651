import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import '../components/layout.css'

export const pageQuery = graphql`
  query {
    allFile(sort: {fields: name, order: DESC}, filter: {relativeDirectory: {eq: "issues"}}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData( width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`

function* range(start, end, step) {
  const condition = start > end
  while (condition ? start >= end : start <= end) {
    yield start;
    start += step;
  }
}

const SecondPage = ({ location, data }) => {
  return <Layout location={location}>
    <Seo title="Catalogues" />
    <h1 className="has-text-centered	">This is our historical issues</h1>
    
      <div className="columns is-multiline">
      {data.allFile.nodes.map((node, i) => {
        const year = node.name
        const image = getImage(node)
        return <a key={i} className="has-text-centered	column is-4 " style={{cursor: year > 2015 ? 'pointer' : 'auto'}}
         href={year > 2015 ? `https://kandb.s3.eu-north-1.amazonaws.com/fullpdf/K%26B_${year}.pdf` : '#'}>
            <GatsbyImage
              image={image}
              alt={year}
              style={{ marginBottom: `1.45rem` }}
            />
          </a>
      })}
      </div>

  </Layout>
}

export default SecondPage
